import { Image, ImageProps } from "@chakra-ui/react";
import { memo } from "react";

export const Logo = memo<ImageProps>(({ ...props }) => {
  return (
    <Image
      src="/speria_logo.png"
      srcSet="/speria_logo.png 1x, /speria_logo_2x.png 2x, /speria_logo_3x.png 3x"
      alt="Logo"
      w="50px"
      {...props}
    />
  );
});
