import { chakra, keyframes } from "@chakra-ui/react";
import { memo } from "react";

const rotate = keyframes`  
100% {
  transform: rotate(360deg);
}
`;
const stretch = keyframes`  
0% {
  stroke-dasharray: 0, 150;
  stroke-dashoffset: 0;
}
50% {
  stroke-dasharray: 75, 150;
  stroke-dashoffset: -25;
}
100% {
  stroke-dashoffset: -100;
}
`;

export type LoadingProps = {
  size?: number;
  color?: string;
  bgOpacity?: number;
};

/**
 * データの読み込み等のローディングアニメーション。
 * ページの読み込み時の表示時には、基本的には LoadingBox を使用すること。
 **/
export const Loading = memo<LoadingProps>(({ size, color, bgOpacity }) => {
  size = size ?? 40;
  color = color ?? "#4A5568";
  bgOpacity = bgOpacity ?? 0.2;

  const speed = "2s";

  return (
    <chakra.svg
      viewBox="0 0 40 40"
      height={`${size}px`}
      width={`${size}px`}
      transformOrigin="center"
      willChange="transform"
      overflow="visible"
      animation={`${rotate} ${speed} linear infinite`}
    >
      <chakra.circle
        cx="20"
        cy="20"
        r="17.5"
        pathLength="100"
        strokeWidth="5px"
        fill="none"
        stroke={color}
        opacity={bgOpacity}
        transition={`stroke 0.5s ease`}
      />
      <chakra.circle
        cx="20"
        cy="20"
        r="17.5"
        pathLength="100"
        strokeWidth="5px"
        fill="none"
        stroke={color}
        strokeDasharray="1 200"
        strokeDashoffset="0"
        strokeLinecap="round"
        willChange="stroke-dasharray, stroke-dashoffset"
        transition={`stroke 0.5s ease`}
        animation={`${stretch} calc(${speed} * 0.75) ease-in-out infinite`}
      />
    </chakra.svg>
  );
});

// ref: https://uiball.com/ldrs/
