import { Center, BoxProps } from "@chakra-ui/react";
import { Loading } from "./loading";

/**
 * データの読み込み等で使用する。
 */
export const LoadingBox = (props: BoxProps & { size?: "screen" }) => {
  return (
    <Center py="64px" {...props}>
      <Loading size={48} />
    </Center>
  );
};
